<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content">
                <!-- <p class="title">廉政投诉</p> -->
                <!-- <p class="desc max_small_box">广东省东莞市质量监督检测中心(以下简称东莞质检）是广东省东莞市市场监督管理局下属事业单位，是国家法定质量检测机构。东莞质检成立于1987年，30多年来东莞质检为政府质量监督、企业质量管理、地方经济发展提供了强有力的技术支撑。 </p> -->
                <!-- <img class="banner max_big_box" src="@/assets/images/wechart.png" alt="" />
                <div class="line max_big_box"></div> -->
                <!-- <p class="desc max_small_box">感谢您对我们的信任与关注，请根据下方提示填写您的真实信息，以便我们更好的为您服务！</p>
                <div class="form max_small_box">
                    <div class="left">
                        <div class="list">
                            <input v-model="form.username" placeholder="请输入姓名" />
                        </div>
                        <div class="list">
                            <input v-model="form.phone" placeholder="请输入联系电话" />
                        </div>
                        <div class="list">
                            <input v-model="form.email" placeholder="请输入电子邮箱" />
                        </div>
                        <div class="list">
                            <input v-model="form.region" placeholder="请输入所在地区" />
                        </div>
                        <div class="list">
                            <input  v-model="form.address" placeholder="请输入联系地址" />
                        </div>
                    </div>
                    <div class="right">
                        <textarea v-model="form.content" placeholder="请输入内容"></textarea>
                    </div>
                </div>
                <button class="btn" @click="submit">提交</button>
                <a-alert
                    v-show="alert"
                    style="width: 30%;margin:0 auto;margin-top:30px;"
                    :message="message"
                    banner
                />
                <a-alert v-show="success" message="成功" type="success" style="width: 30%;margin:0 auto;margin-top:30px;" /> -->
                <div class="max_small_box" v-if="detail" v-html="detail.intro"></div>
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { get, post } from '../../utils/request';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ContactTop from '../../components/contact/ContactTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//表单处理逻辑
const formEffect = () => {
    const form = reactive({
        username: '',
        phone: '',
        email: '',
        content: '',
        region: '',
        industry: '',
        company: '',
        address: '',
        fax: ''
    })

    const message = ref('')

    const alert = ref(false)

    const success = ref(false)

    const submit = () => {
        if(form.username == ''){
            alert.value = true
            message.value = '请输入联系人'
        }else if(form.phone == ''){
            alert.value = true
            message.value = '请输入联系电话'
        }else if(form.email == ''){
            alert.value = true
            message.value = '请输入邮箱'
        }else if(form.content == ''){
            alert.value = true
            message.value = '请输入内容'
        }else if(form.region == ''){
            alert.value = true
            message.value = '请输入所属地区'
        }else if(form.address == ''){
            alert.value = true
            message.value = '请输入地址'
        }else{
            setPost()
        }
    }

    const setPost = async () => {
        await post('/contact/add_complaints', form).then((res) => {
            // console.log('添加投诉', res)
            if(res.code == 1){
                alert.value = false
                success.value = true
            }
        })
    }

    return { form, submit, message, alert, success }
}

//详情处理模块
const detailEffect = () => {

    //详情
    const detail = ref()

     //获取详情
    const getDetail = async () => {
        let postData = {
            page_id: '/',
            name: '举报投诉'
        }
        try{
            const result = await post('/index/get_statement', postData)
            // console.log('举报投诉详情', result)
            if(result.code == 1){
                detail.value = result.data
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    } 

    return { detail, getDetail }
}

export default {
    name: 'ContactComplaint',
    components: { Header, Footer, FloatingFrame, ContactTop },
    setup() {

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { form, submit, message, alert, success } = formEffect()

        //详情处理模块
        const { detail, getDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        getDetail()

        return { isShow, header, form, submit, message, alert, success, detail }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 50px;
}
.content{
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .title{
        color: #2F509E;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .desc{
        color: rgba(16, 16, 16, 100);
        margin-top: 30px;
        padding: 0 60px;
        line-height: 24px;
        text-align: center;
    }
    .banner{
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 50px;
    }
    .line{
        width: 100%;
        height: 1px;
        background: #bbb;
        margin-top: 50px;
    }
    .form{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @media screen and (max-width: 1200px){
            display: block;
        }
        .left{
            width: 45%;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            .list{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                .name{
                    width: 80px;
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    font-weight: $all-font-weight;
                }
                input{
                    width: 100%;
                    border: 1px solid #BBBBBB;
                    padding: 10px 20px;
                    height: 50px;
                    outline: none;
                    color: #888888;
                    &::placeholder{
                        color: #888888;
                    }
                }
            }
        }
        .right{
            width: 45%;
            display: flex;
            @media screen and (max-width: 1200px){
                width: 100%;
                margin-left: 0%;
            }
            .name{
                width: 80px;
                color: rgba(16, 16, 16, 100);
                font-size: 16px;
                font-weight: $all-font-weight;
            }
            textarea{
                width: 100%;
                height: 370px;
                border: 1px solid #BBBBBB;
                padding: 10px 15px; 
                outline: none;
                color: #888888;
                &::placeholder{
                    color: #888888;
                }
                @media screen and (max-width: 1200px){
                    width: 70%;
                }
            }
        }
    }
    .btn{
        color: #fff;
        background: #2F509E;
        font-size: 16px;
        border: none;
        padding: 15px 30px;
        width: 150px;
        margin: 0 auto;
        display: block;
        margin-top: 100px;
        cursor: pointer;
        transition: .3s all;
    }
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>